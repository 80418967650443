export default {
    state: {
        title: null,
        description: null,
        action: null,
        showError: false
    },
    getters: {
        isError({ showError }) {
            return showError
        },
        options({ title, description, action }) {
            return {
                title,
                description,
                action
            }
        }
    },
    mutations: {
        showError(state, { title = null, description = null, action = null } = {}) {
            state.showError = true

            state.title = title
            state.description = description
            state.action = action
        },
        closeError(state) {
            state.showError = false
            state.title = null
            state.description = null
            state.action = null
        }
    },
    namespaced: true,
}