import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@index/store';

import App from '@index/App.vue'

import checkUserGuard from '@index/guards/checkUser'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    beforeEnter: checkUserGuard,
    component: App,
    children: [
      {
        path: '/2lk',
        component: () => import('@index/layouts/personal-area/PersonalArea'),
        children: [
          {
            path: '',
            name: 'Profile',
            component: () => import('@index/views/personal-area/profile/Profile'),
            meta: {
              title: 'Профиль'
            }
          },
          {
            path: 'edit-personal-data',
            name: 'EditPersonalData',
            component: () => import('@index/views/personal-area/edit-personal-data/EditPersonalData'),
            meta: {
              title: 'Личные данные'
            }
          },
          {
            path: 'edit-passport',
            name: 'EditPassport',
            component: () => import('@index/views/personal-area/edit-passport/EditPassport'),
            meta: {
              title: 'Паспортные данные'
            }
          }
        ]
      },
      {
        path: '',
        name: 'Main',
        component: () =>
            import(/* webpackChunkName: "main" */ '@index/layouts/main/Main.vue'),
      },
      {
        path: '/',
        component: () =>
            import(
                /* webpackChunkName: "contact-information" */ '@index/layouts/application/Application.vue'
                ),
        children: [
          {
            name: 'Login',
            path: '/login',
            component: () =>
                import(/* webpackChunkName: "login" */ '@index/views/login/Login.vue'),
          },
          {
            path: '/primary',
            name: 'ContactInfo',
            component: () =>
                import(
                    /* webpackChunkName: "contact-information" */ '@index/views/application/contact-information/ContactInformation.vue'
                    ),
          },
          {
            path: '/anticharge',
            name: 'Anticharge',
            component: () =>
                import(
                    /* webpackChunkName: "contact-information" */ '@index/views/application/contact-information/ContactInformation.vue'
                    ),
          },
          {
            path: '/poll/contact',
            name: 'PersonalInfo',
            component: () =>
                import(
                    /* webpackChunkName: "personal-info" */ '@index/views/application/personal-information/PersonalInformation.vue'
                    ),
          },
          {
            path: '/poll/passport',
            name: 'PassportInfo',
            component: () =>
                import(
                    /* webpackChunkName: "passport-info" */ '@index/views/application/passport-information/PassportInformation.vue'
                    ),
          },
          {
            path: 'sign',
            name: 'Sign',
            component: () =>
                import(
                    /* webpackChunkName: "sign" */ '@index/views/application/codes/Sign.vue'
                    ),
          },
          {
            path: 'auth',
            name: 'Auth',
            component: () =>
                import(
                    /* webpackChunkName: "auth" */ '@index/views/application/codes/Auth.vue'
                    ),
          },
          {
            path: 'card',
            name: 'Card',
            component: () =>
                import(
                    /* webpackChunkName: "card-info" */ '@index/views/application/payment/card/Card.vue'
                    ),
          },
        ],
      },
      {
        path: 'unsubscribe',
        name: 'Unsubscribe',
        component: () =>
            import(/* webpackChunkName: "unsubscribe" */ '@index/layouts/unsubscribe/Unsubscribe.vue'),
      },
      {
        name: 'Lite',
        path: '/lite',
        component: () =>
            import(/* webpackChunkName: "lite" */ '@index/layouts/lite/Lite.vue'),
      },
      {
        path: '',
        name: 'Popup',
        component: () =>
            import(/* webpackChunkName: "popup" */ '@index/layouts/popup/Popup.vue'),
        children: [
          {
            path: '/unsubscribe-info',
            name: 'UnsubscribeInfo',
            component: () =>
                import(/* webpackChunkName: "unsubscribeinfo" */ '@index/views/popup/unsubscribe-info/UnsubscribeInfo.vue'),
          },
          {
            path: '/unsubscribe-message',
            name: 'UnsubscribeMessage',
            component: () =>
                import(/* webpackChunkName: "unsubscribemsg" */ '@index/views/popup/unsubscribe-message/UnsubscribeMessage.vue'),
          },
          {
            path: 'card-secure',
            name: 'CardSecure',
            meta: {
              fullContent: true
            },
            component: () =>
                import(
                    /* webpackChunkName: "card-secure" */ '@index/views/application/payment/3ds/ThreeDS.vue'
                    ),
          }
        ]
      },
      {
        name: 'Final',
        path: '/final',
        component: () => import(/* webpackChunkName: "final" */ '@index/layouts/final/Final.vue')
      },
      {
        path: '/ticket/:uid',
        name: 'TicketInfo',
        component: () => import('@index/layouts/ticket-info/TicketInfo.vue')
      },
      {
        name: 'NotFound',
        path: '*',
        component: () => import('@index/layouts/not-found/NotFound.vue')
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  Store.commit('application/load', true)
  next()

  const p = to.name === 'Main' ? '1' : '2';
  const src = '/api/cpa?p=' + p;

  const isExist = !!document.querySelector(`img[src="${src}"]`)

  if (isExist)
    return

  const img = document.createElement('img');
  img.width = 0;
  img.height = 0;
  img.src = src;
  img.style = 'display: none';
  document.body.appendChild(img);
})

router.afterEach(() => {
  Store.commit('application/load', false)
})

export default router;
