<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'App',
    created() {
        const amount = +Cookies.get('sum')
        let term = Cookies.get('term')

        if (isNaN(amount)) {
            return
        }

        if (term) {
            try {
                term = JSON.parse(term)

                if (!term.count) {
                    throw new Error();
                }

                this.$store.commit('application/updateCalculator', { amount, term })
            } catch (e) {
                console.log(e)
                this.$store.commit('application/updateCalculator', { amount })
            }
        } else {
            this.$store.commit('application/updateCalculator', { amount })
        }
    },
    computed: {
      ...mapGetters({
          loader: 'application/loader'
      })
    },
    watch: {
      loader(v) {
          if (!v) {
              document.querySelector('.loader-wrapper').setAttribute('style', 'display:none')
              document.body.removeAttribute('style')
          } else {
              document.querySelector('.loader-wrapper').setAttribute('style', 'display:block')
              document.body.setAttribute('style', 'overflow:hidden;pointer-events:none;user-select:none;')
          }
      }
    }
};
</script>
