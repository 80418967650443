import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import application from './modules/application'
import dictionary from './modules/dictionary'
import error from './modules/error'

export default new Vuex.Store({
    modules: {
        application,
        dictionary,
        error
    },
    strict: true,
})
